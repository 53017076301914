<template>
  <div class="d-flex flex-wrap align-items-center">
    <div class="mr-auto">
      <p class="text-uppercase text-dark font-weight-bold mb-1">
        {{ data.title }}
      </p>
      <p class="text-gray-5 mb-0">
        {{ data.desc }}
      </p>
    </div>
    <!-- text-success -->
    <p class=" font-weight-bold font-size-24 mb-0">
      <span v-if="isAmount">&#8377;</span>{{ data.value || 0 }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'SideNumberCard',
  props: {
    data: {
      type: Object,
      default: () => {
        return { title: 'NA', desc: 'NA', value: 0 }
      },
      required: true,
    },
    isAmount: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
