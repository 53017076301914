
import { DashboardInsightsDocument } from '@/types/firebaseCollectionContracts/DashboardInsightsDocument'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import SideNumberCard from '../../../components/Widgets/SideNumberCard/index.vue'
import SideHighlightNumberCard from '../../../components/Widgets/SideHighlightNumberCard/index.vue'

export default defineComponent({
  name: 'VbDashboard',
  components: { SideNumberCard, SideHighlightNumberCard },
  setup() {
    const store = useStore()
    const isLoading = computed(() => store.state.appGlobals.isGlobalSetup as boolean)
    const insightsDoc = computed(
      () => store.state.appGlobals.dashboardInsights as DashboardInsightsDocument,
    )

    // TODO: Check if any one value updates then whole data will be recomputed or not.
    // If yes, that can be expensive.
    const overviewData = computed(() => {
      const customers = getCardData('Total Customers', 'All time', insightsDoc.value.totalCustomers)
      const vendors = getCardData('Total Vendors', 'All time', insightsDoc.value.totalVendors)
      const services = getCardData('Total Services', 'All time', insightsDoc.value.totalServices)
      const receivables = getCardData(
        'Total Receivables',
        'Current FY',
        insightsDoc.value.totalReceivables,
      )
      const consignments = getCardData(
        'Total Consignments',
        'Current FY',
        store.getters['appGlobals/totalConsignments'](true) as number,
      )
      const invoices = getCardData(
        'Total Invoices',
        'Current FY',
        store.getters['appGlobals/totalInvoices'](true) as number,
      )
      const unbilledAmount = getCardData(
        'Total Unbilled Amount',
        'Current FY',
        store.getters['appGlobals/unbilledAmount'](true) as number,
      )
      const totalCashAmount = getCardData(
        'Total Cash Payment',
        'Current FY',
        store.getters['appGlobals/totalCashAmount'](true) as number,
      )
      const totalSales = getCardData(
        'Total Sales',
        'Current FY',
        store.getters['appGlobals/totalSales'](true) as number,
      )
      return {
        customers,
        vendors,
        services,
        receivables,
        consignments,
        invoices,
        unbilledAmount,
        totalCashAmount,
        totalSales,
      }
    })

    const getCardData = (title: string, desc: string, value: number) => {
      return {
        title,
        desc,
        value,
      }
    }
    return {
      overviewData,
      isLoading,
    }
  },
})
