<template>
  <div class="pt-3" :class="$style.container">
    <div :class="[$style.status, `bg-${bgType}`]" />
    <div class="d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4">
      <div class="mr-auto">
        <div class="text-uppercase font-weight-bold font-size-24 text-dark">
          <span v-if="isAmount">&#8377;</span>{{ data.value || 0 }}
        </div>
        <div class="font-size-18">
          {{ data.title }}
        </div>
      </div>
      <!-- <div class="ml-1 text-success">
        <i class="fe fe-arrow-left-circle font-size-40" />
      </div> -->
    </div>
    <div class="py-3 pl-4" :class="$style.footer">
      {{ data?.desc }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'SideHighlightNumberCard',
  props: {
    data: {
      type: Object,
      default: () => {
        return { title: 'NA', desc: 'NA', value: 0 }
      },
      required: true,
    },
    isAmount: {
      type: Boolean,
      default: () => false,
    },
    bgType: {
      type: String,
      default: () => 'secondary',
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
